.messages-div {
    display: flex;
    width: 100%;
    justify-content: space-around;
    column-gap: 2vw;
    margin-top: 2vh;
    height: fit-content;
}
.oppo-heading {
    width: 100%;
    min-height: fit-content;
    background-color: #d9d9d9;
    border-radius: 25px;
}
h4 {
    margin: 3vh;
}
.message-desc {
    margin: 3vh;
    text-align: left;
}
@media (max-width: 701px) {
    .messages-div {
        flex-direction: column;
        height: fit-content;
    }
    .coming-soon {
        margin-top: 2vh;
        border-radius: 15px;
        height: fit-content;
    }
    .new-here {
        border-radius: 15px;
        height: fit-content;
    }
    h4 {
        margin: 5vw;
    }
}