img.center { 
    width:180px; 
    display:block; 
    margin:0 auto; 
}
.centerText {
    text-align:center;
    display: block;
    margin: 10px 0 30px 0;
}
.stakes-table {
    border-collapse: collapse;
    width: 100%;
}
th {
    color: #535353;
    font-weight: 500;
    font-size: 10px;
    padding: 5px;
}
td {
    border-top: 1px solid black;
    padding: 6px 0px 6px;
    text-align: center;
    font-weight: 600;
    font-size: max(1.6vh, 14px);
}
.exchange-tile {
    display: flex;
    justify-content: left;
    align-items: center;
    height: 100%;
    margin: auto auto auto 2vw;
}
.exchange-icon {
    height: 7vh;
    border-radius: 50%;
    overflow: none;
    margin: auto 10px auto 3px;
}
.buttons-div {
    display: flex;
}
.button-text {
    font-family: "Inter";
    font-weight: 600;
}
.claim-button {
    padding: 1.25vh 1.75vh 1.25vh;
    margin: auto 0 auto auto;
    border: none;
    border-radius: 10px;
    cursor: pointer;
}
.stake-button {
    padding: 1.25vh 2vh 1.25vh;
    margin: auto 0 auto 10px;
    background-color: #696969;
    border: none;
    border-radius: 10px;
    cursor: pointer;
}
@media (max-width: 601px) {
    tr {
        margin-left: auto;
        width: 70vw;
    }
}