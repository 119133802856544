nav {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  left: 50%;
  transform: translate(-50%);
  top: 0;
}
.background {
  position: absolute;
  background-color: black;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}
.active-desc {
  margin: 3px 0 3px;
  font-size: 14px;
}
.active-header {
  display: flex;
  font-weight: 700;
  font-size: 24px;
}
.active-icon {
  margin-right: min(1vw, 7px);
}
.logo {
  position: relative;
  object-fit: cover;
  height: 13.5vh;
  width: 250px;
}
.button-container {
  position: absolute;
  right: 10%;
}
.dash-oppo {
  margin-top: auto;
}
.dashboard {
  display: flex;
  background-color: #C0C0C0;
  margin-top: 14vh;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  width: 85vw;
  min-height: 86vh;
  height: fit-content;
  border-radius: 50px 50px 0 0;
}
.inner-dash {
  display: flex;
  height: 100%;
  flex-direction: column;
  width: 100%;
  margin: 4vh 4vw 3vh;
}
.dash-header {
  display: grid;
  grid-template-columns: auto auto;
}
.update-modal {
  display: flex;
  justify-content: right;
  align-items: center;
}
.refresh-icon {
  margin-right: min(1vw, 6px);
}
.update-status {
  display: flex;
  align-content: center;
  justify-content: center;
  font-size: 12px;
  color:  #535353;
  margin: 0 1vh 0 0;
}

@media (max-width: 701px) {
  .logo {
    width: 150px;
    height: 10vh;
  }
  .button-container {
    top: 10vh;
    right: auto;
  }
  .dashboard {
    width: 100%;
    margin-top: 17vh;
    min-height: 83vh;
  }
  .inner-dash {
    margin-top: 2vh;
  }
  .dash-header {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto;
  }
  .dash-header {
    justify-content: center;
    text-align: center;
  }
  .active-accounts-div {
    margin-top: 2.25vh;
    margin-bottom: 1vh;
  }
  p {
    margin-top: 1vh;
  }
  .refresh-icon {
    margin-right: 1vw;
  }
  .update-modal {
    grid-row: 1;
  }
  .active-header {
    justify-content: center;
  }
}