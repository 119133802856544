a {
    color: black;
    justify-self: right;
}
.oppo-header {
    display: flex;
    font-weight: 700;
    font-size: 24px;
}
.oppo-icon {
    margin-right: min(1vw, 7px);
}
.oppo-desc {
    margin: 3px 0 3px;
    font-size: 14px;
}
.stakes-table {
    border-collapse: collapse;
    width: 100%;
}
th {
    color: #535353;
    font-weight: 500;
    font-size: 10px;
    padding: 5px;
}
td {
    border-top: 1px solid black;
    padding: 6px 0px 4px;
    text-align: center;
    font-weight: 600;
    font-size: max(1.6vh, 14px);
}
.exchange-tile {
    display: flex;
    justify-content: left;
    align-items: center;
    height: 100%;
    width: fit-content;
    margin: auto auto auto 2vw;
}
.exchange-icon {
    height: 7vh;
    border-radius: 50%;
    overflow: none;
    margin: auto 1.25vw auto 3px;
}
.buttons-div {
    display: flex;
}
.button-text {
    font-family: "Inter";
    font-weight: 600;
    color: black;
}
.visit-button {
    padding: 1.8vh 1.95vh 1.8vh;
    margin: auto 0 auto auto;
    border: none;
    border-radius: 10px;
    cursor: pointer;
}
.button-link {
    width: 100%;
    height: 100%;
}
.end-rule {
    border: 1.75px solid black;
    background-color: black;
    border-radius: 3px;
}
@media (max-width: 701px) {
    .oppo-header {
        justify-content: center;
    }
    tr {
        margin-left: auto;
        width: 70vw;
    }
    .oppo-accounts-div {
        margin-top: 2.75vh;
        margin-bottom: 1vh;
    }
    p {
        margin-top: 1vh;
        text-align: center;
    }

}